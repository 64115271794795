import Image from 'next/image';

import { cx } from '@/core/utils';
import bgImage from '@/assets/images/home/video-section-bg.png';

import { YoutubeVideo } from './YoutubeVideo';

type VideoSectionProps = {
  video: string;
  title?: string;
};

export function VideoSection(props: VideoSectionProps) {
  const { video, title } = props;

  return (
    <section
      className={cx(
        'relative isolate overflow-x-clip bg-gradient-to-b from-[#040202] via-[#1D0000] to-black py-[4.5rem]',
        'md:py-28'
      )}
    >
      <div
        className={cx(
          'container mx-auto grid max-w-screen-2xl grid-cols-1 gap-x-6 px-4',
          'md:grid-cols-12'
        )}
      >
        {title && (
          <h2
            className={cx(
              'col-span-full -translate-y-16 px-4 text-center text-neutral-500',
              'sm:-translate-y-24',
              'md:text-h4'
            )}
          >
            {title}
          </h2>
        )}

        <div
          className={cx(
            'relative col-span-full overflow-visible px-4',
            'xl:col-start-2 xl:col-end-12'
          )}
        >
          <YoutubeVideo
            src={video}
            height={720}
            width={1280}
            className={cx('aspect-video h-auto w-full')}
          />
          <div
            className={cx(
              'pointer-events-none absolute inset-x-0 top-1/2 -z-10 origin-center -translate-y-1/2'
            )}
          >
            <Frame />
          </div>
        </div>
      </div>

      <Image
        src={bgImage}
        alt=""
        placeholder="blur"
        className={cx('pointer-events-none absolute inset-0 -z-10 object-cover object-center')}
      />
    </section>
  );
}

function Frame() {
  return (
    <svg
      width="916"
      height="560"
      viewBox="0 0 916 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('h-auto w-full')}
    >
      <g opacity="0.32">
        <path d="M45.6807 0.837402H0.342773V4.42572H45.6807V0.837402Z" fill="#FF2948" />
        <path
          opacity="0.36"
          d="M196.659 0.837402H151.321V4.42572H196.659V0.837402Z"
          fill="#FF2948"
        />
        <path
          opacity="0.36"
          d="M312.154 0.837402H302.298V4.42572H312.154V0.837402Z"
          fill="#FF2948"
        />
        <path opacity="0.36" d="M427.65 0.837402H417.794V4.42572H427.65V0.837402Z" fill="#FF2948" />
        <path
          opacity="0.36"
          d="M543.146 0.837402H533.29V4.42572H543.146V0.837402Z"
          fill="#FF2948"
        />
        <path
          opacity="0.36"
          d="M680.201 0.837402H648.786V4.42572H680.201V0.837402Z"
          fill="#FF2948"
        />
        <path
          opacity="0.36"
          d="M795.696 0.837402H785.84V4.42572H795.696V0.837402Z"
          fill="#FF2948"
        />
        <path d="M911.192 0.837402H901.336V4.42572H911.192V0.837402Z" fill="#FF2948" />
        <path
          d="M915.081 18.4477C914.758 18.4477 914.504 18.3205 914.504 18.1641V8.9831H1.49651V18.1641C1.49651 18.3205 1.2423 18.4477 0.919642 18.4477C0.596987 18.4477 0.342773 18.3205 0.342773 18.1641V8.70933C0.342773 8.55289 0.596987 8.42578 0.919642 8.42578H915.081C915.403 8.42578 915.658 8.55289 915.658 8.70933V18.1739C915.658 18.3303 915.403 18.4574 915.081 18.4574V18.4477Z"
          fill="#FF2948"
        />
      </g>
      <rect
        x="103.066"
        y="81.4873"
        width="709.867"
        height="397.589"
        fill="black"
        fillOpacity="0.1"
      />
      <rect
        x="103.066"
        y="81.4873"
        width="709.867"
        height="397.589"
        stroke="#CC2733"
        strokeWidth="0.5"
      />
      <g opacity="0.32">
        <path
          d="M0.924525 542.106C1.24718 542.106 1.50139 542.234 1.50139 542.39V551.571H914.499V542.39C914.499 542.234 914.753 542.106 915.076 542.106C915.398 542.106 915.653 542.234 915.653 542.39V551.855C915.653 552.011 915.398 552.138 915.076 552.138H0.924525C0.60187 552.138 0.347656 552.011 0.347656 551.855V542.39C0.347656 542.234 0.60187 542.106 0.924525 542.106Z"
          fill="#FF2948"
        />
        <path d="M0.347468 559.727H10.2031V556.138H0.347468V559.727Z" fill="#FF2948" />
        <path opacity="0.36" d="M115.843 559.727H125.699V556.138H115.843V559.727Z" fill="#FF2948" />
        <path opacity="0.36" d="M231.339 559.727H262.754V556.138H231.339V559.727Z" fill="#FF2948" />
        <path opacity="0.36" d="M368.394 559.727H378.25V556.138H368.394V559.727Z" fill="#FF2948" />
        <path opacity="0.36" d="M483.89 559.727H493.745V556.138H483.89V559.727Z" fill="#FF2948" />
        <path opacity="0.36" d="M599.385 559.727H609.24V556.138H599.385V559.727Z" fill="#FF2948" />
        <path opacity="0.36" d="M714.881 559.727H760.219V556.138H714.881V559.727Z" fill="#FF2948" />
        <path d="M865.859 559.727H911.197V556.138H865.859V559.727Z" fill="#FF2948" />
      </g>
    </svg>
  );
}
