'use client';

import * as React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import { HiArrowLeft } from 'react-icons/hi2';

import { cx } from '@/core/utils';
import bgImage from '@/assets/images/home/intro-section-bg.svg';
import rleshnerImage from '@/assets/images/home/pfp_metal_robert-leshner.svg';
import warcMeinsteinImage from '@/assets/images/home/pfp_metal_marc-weinstein.svg';
import kaiynneImage from '@/assets/images/home/pfp_metal_kain-warwick.svg';
import tarunchitraImage from '@/assets/images/home/pfp_metal_tarun-chitra.svg';
import asvanevikImage from '@/assets/images/home/pfp_metal_alex-svanevik.svg';

const t = {
  title: 'Our investors',
  items: [
    {
      label: '@rleshner',
      href: 'https://twitter.com/rleshner',
      company: 'Founder @ Compound',
      companyHref: 'https://compound.finance/',
      image: rleshnerImage,
    },
    {
      label: '@WarcMeinstein',
      href: 'https://twitter.com/WarcMeinstein',
      company: 'Parter @ Mechanism Cap',
      companyHref: 'https://mechanism.capital/',
      image: warcMeinsteinImage,
    },
    {
      label: '@kaiynne',
      href: 'https://twitter.com/kaiynne',
      company: 'Founder @ Synthetix',
      companyHref: 'https://www.synthetix.io/',
      image: kaiynneImage,
    },
    {
      label: '@tarunchitra',
      href: 'https://twitter.com/tarunchitra',
      company: 'Founder @ Gauntlet',
      companyHref: 'https://gauntlet.xyz/',
      image: tarunchitraImage,
    },
    {
      label: '@ASvanevik',
      href: 'https://twitter.com/ASvanevik',
      company: 'Founder @ Nansen',
      companyHref: 'https://nansen.ai/',
      image: asvanevikImage,
    },
  ],
};

const PAGINATION_BUTTON_CLASSES = cx(
  'flex h-10 w-10 shrink-0 items-center justify-center bg-[#9C9CD8]/15 text-neutral-600',
  'sm:h-12 sm:w-12',
  'hover:bg-[#9C9CD8]/30',
  'active:bg-[#9C9CD8]/40'
);

export function CharactersSection() {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    loop: true,
    align: 'start',
  });

  React.useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        setCurrentSlide(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  return (
    <section className={cx('relative isolate overflow-x-clip py-[4.5rem]')}>
      <div
        className={cx(
          'container mx-auto max-w-screen-2xl overflow-visible px-4',
          'grid grid-cols-1 gap-x-6',
          'md:grid-cols-12'
        )}
      >
        <h2
          className={cx(
            'col-span-full mb-6 text-center text-lg text-brokenWhite',
            'md:col-span-3 md:mb-0 md:text-left md:text-2xl'
          )}
        >
          {t.title}
        </h2>
        <div
          ref={emblaRef}
          className={cx(
            'embla relative col-span-full mb-6',
            'sm:mb-8',
            'md:col-start-5 md:col-end-13 md:overflow-clip'
          )}
        >
          <div className={cx('embla__container flex')}>
            {t.items.map((item, index) => (
              <div
                key={index}
                className={cx(
                  'embla__slide backdrop-blur[6px] mr-5 flex w-64 shrink-0 flex-col items-start gap-4 rounded border border-neutral-800 bg-gradient-to-b from-gradient-chars-1/30 to-gradient-chars-2/50 p-6',
                  'sm:gap-5 sm:p-8'
                )}
              >
                <div>
                  <Image
                    src={item.image}
                    alt={item.label}
                    width={76}
                    height={76}
                    className={cx(
                      'h-16 w-16 shrink-0 rounded-full border border-purple-light object-cover object-center',
                      'sm:h-[4.75rem] sm:w-[4.75rem]'
                    )}
                  />
                </div>

                <div className={cx('flex grow flex-col truncate')}>
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cx('truncate text-sm font-bold text-primary-500', 'sm:text-base')}
                  >
                    {item.label}
                  </a>
                  <a
                    href={item.companyHref}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cx('font-display text-base text-cyan', 'sm:text-xl')}
                  >
                    {item.company}
                  </a>
                </div>
              </div>
            ))}
          </div>

          {/* pagination */}
          <div className={cx('mt-8 flex items-center justify-between')}>
            <div className={cx('hidden shrink-0 gap-1', 'sm:flex')}>
              {t.items.map((_, index) => (
                <button
                  key={index}
                  className={cx('py-3')}
                  onClick={() => emblaApi?.scrollTo(index)}
                >
                  <i
                    className={cx(
                      'block h-0.5 w-6 shrink-0 bg-primary-500',
                      index !== currentSlide && 'opacity-40'
                    )}
                  />
                </button>
              ))}
            </div>
            <div className={cx('flex gap-3')}>
              <button
                aria-label="previous slide"
                className={PAGINATION_BUTTON_CLASSES}
                onClick={() => emblaApi?.scrollPrev()}
              >
                <HiArrowLeft className={cx('h-6 w-6')} />
              </button>

              <button
                aria-label="next slide"
                className={PAGINATION_BUTTON_CLASSES}
                onClick={() => emblaApi?.scrollNext()}
              >
                <HiArrowLeft className={cx('h-6 w-6 rotate-180 transform')} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <Image
        src={bgImage}
        alt=""
        className={cx(
          'pointer-events-none absolute inset-0 -z-10 h-full w-full object-cover object-center'
        )}
      />
    </section>
  );
}
