'use client';

import Image from 'next/image';
import { useState } from 'react';

import bgImage from '@/assets/images/home/email-section-bg.png';
import { cx } from '@/core/utils';

const t = {
  title: 'Stay updated',
  emailPlaceholder: 'Enter your email',
  emailButton: 'Submit',
};

function Callout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className="mx-auto mt-10 flex max-w-md md:hidden">{children}</div>
      <svg
        width="595"
        height="183"
        viewBox="0 0 595 183"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mx-auto mt-6 hidden max-w-xl md:block"
      >
        <path
          d="M585.307 107.665H535.427C532.051 107.665 529.079 105.906 527.462 102.953C525.844 99.9988 525.967 96.5352 527.778 93.7044L552.727 54.8129C554.398 52.1932 557.263 50.646 560.358 50.646C563.452 50.646 566.318 52.2108 567.988 54.8129L592.92 93.7044C594.731 96.5352 594.854 99.9988 593.254 102.953C591.636 105.906 588.665 107.665 585.289 107.665H585.307ZM560.358 53.9163C558.371 53.9163 556.56 54.9184 555.488 56.5711L530.539 95.4626C529.378 97.2736 529.308 99.4714 530.328 101.37C531.365 103.251 533.264 104.377 535.409 104.377H585.289C587.434 104.377 589.333 103.251 590.37 101.37C591.408 99.4889 591.32 97.2736 590.159 95.4802L565.228 56.5887C564.156 54.9184 562.345 53.9338 560.358 53.9338V53.9163Z"
          fill="#51D2E7"
        />
        <path
          d="M568.779 82.3113H562.221L565.79 63.7095L549.737 82.3113H556.524L552.955 100.931L568.779 82.3113Z"
          fill="#51D2E7"
        />
        <path
          d="M58.9709 107.665H9.09057C5.71481 107.665 2.74344 105.906 1.12589 102.953C-0.491654 99.9988 -0.36858 96.5352 1.44237 93.7045L26.3913 54.7954C28.0616 52.1756 30.9275 50.6284 34.0219 50.6284C37.1164 50.6284 39.9822 52.1932 41.6525 54.7954L66.6015 93.6869C68.4124 96.5176 68.5355 99.9812 66.9179 102.935C65.3004 105.889 62.329 107.647 58.9533 107.647L58.9709 107.665ZM34.0219 53.9163C32.0351 53.9163 30.2242 54.9184 29.1517 56.5712L4.22034 95.4627C3.05992 97.2736 2.98959 99.4714 4.00935 101.37C5.04669 103.252 6.92797 104.377 9.09057 104.377H58.9709C61.1159 104.377 63.0147 103.252 64.0521 101.37C65.0894 99.4889 65.0015 97.2736 63.8411 95.4627L38.8922 56.5712C37.8196 54.9009 36.0087 53.9163 34.0219 53.9163Z"
          fill="#51D2E7"
        />
        <path
          d="M42.4427 82.3113H35.8846L39.4538 63.7095L23.4014 82.3113H30.188L26.6189 100.931L42.4427 82.3113Z"
          fill="#51D2E7"
        />
        <path
          d="M32.9315 38.7956C32.0173 38.7956 31.2964 38.0572 31.2964 37.1605V20.2642C31.2964 19.3499 32.0349 18.629 32.9315 18.629H221.376C222.29 18.629 223.011 19.3675 223.011 20.2642C223.011 21.1608 222.273 21.8993 221.376 21.8993H34.5667V37.1429C34.5667 38.0572 33.8282 38.7781 32.9315 38.7781V38.7956Z"
          fill="#51D2E7"
        />
        <path
          d="M221.376 13.4775H32.9314C32.4743 13.4775 32.1051 13.1083 32.1051 12.6512C32.1051 12.194 32.4743 11.8248 32.9314 11.8248H221.376C221.833 11.8248 222.202 12.194 222.202 12.6512C222.202 13.1083 221.833 13.4775 221.376 13.4775Z"
          fill="#51D2E7"
        />
        <path
          d="M28.9935 11.5787H19.4992C19.0421 11.5787 18.6729 11.2094 18.6729 10.7523V1.258C18.6729 0.800864 19.0421 0.431641 19.4992 0.431641H28.9935C29.4506 0.431641 29.8199 0.800864 29.8199 1.258V10.7523C29.8199 11.2094 29.4506 11.5787 28.9935 11.5787ZM20.308 9.94353H28.1672V2.06677H20.308V9.92595V9.94353Z"
          fill="#51D2E7"
        />
        <path
          d="M28.9934 11.5786C28.7824 11.5786 28.5714 11.4907 28.4132 11.3325L18.9188 1.83818C18.6024 1.5217 18.6024 0.994241 18.9188 0.677765C19.2353 0.361288 19.7628 0.361288 20.0793 0.677765L29.5736 10.1721C29.89 10.4885 29.89 11.016 29.5736 11.3325C29.4153 11.4907 29.2043 11.5786 28.9934 11.5786Z"
          fill="#51D2E7"
        />
        <path
          d="M19.4991 11.5786C19.2881 11.5786 19.0771 11.4907 18.9188 11.3325C18.6024 11.016 18.6024 10.4885 18.9188 10.1721L28.4132 0.677765C28.7296 0.361288 29.2571 0.361288 29.5736 0.677765C29.89 0.994241 29.89 1.5217 29.5736 1.83818L20.0793 11.3325C19.921 11.4907 19.71 11.5786 19.4991 11.5786Z"
          fill="#51D2E7"
        />
        <path d="M32.9329 25.0466H110.136L114.443 20.739L32.9329 20.2642V25.0466Z" fill="#51D2E7" />
        <path
          d="M32.9314 46.2152C32.4743 46.2152 32.1051 45.846 32.1051 45.3889V42.2944C32.1051 41.8373 32.4743 41.4681 32.9314 41.4681C33.3886 41.4681 33.7578 41.8373 33.7578 42.2944V45.3889C33.7578 45.846 33.3886 46.2152 32.9314 46.2152Z"
          fill="#51D2E7"
        />
        <path
          d="M560.323 38.7956C559.408 38.7956 558.688 38.0572 558.688 37.1605V21.8993H371.896C370.982 21.8993 370.261 21.1608 370.261 20.2642C370.261 19.3675 370.999 18.629 371.896 18.629H560.34C561.255 18.629 561.975 19.3675 561.975 20.2642V37.1605C561.975 38.0748 561.237 38.7956 560.34 38.7956H560.323Z"
          fill="#51D2E7"
        />
        <path
          d="M560.323 13.4775H371.878C371.421 13.4775 371.052 13.1083 371.052 12.6512C371.052 12.194 371.421 11.8248 371.878 11.8248H560.323C560.78 11.8248 561.149 12.194 561.149 12.6512C561.149 13.1083 560.78 13.4775 560.323 13.4775Z"
          fill="#51D2E7"
        />
        <path
          d="M573.755 11.5787H564.261C563.804 11.5787 563.435 11.2094 563.435 10.7523V1.258C563.435 0.800864 563.804 0.431641 564.261 0.431641H573.755C574.212 0.431641 574.582 0.800864 574.582 1.258V10.7523C574.582 11.2094 574.212 11.5787 573.755 11.5787ZM565.07 9.94353H572.929V2.06677H565.07V9.92595V9.94353Z"
          fill="#51D2E7"
        />
        <path
          d="M564.244 11.5786C564.033 11.5786 563.822 11.4907 563.663 11.3325C563.347 11.016 563.347 10.4885 563.663 10.1721L573.158 0.677765C573.474 0.361288 574.002 0.361288 574.318 0.677765C574.635 0.994241 574.635 1.5217 574.318 1.83818L564.824 11.3325C564.666 11.4907 564.455 11.5786 564.244 11.5786Z"
          fill="#51D2E7"
        />
        <path
          d="M573.755 11.5786C573.544 11.5786 573.333 11.4907 573.175 11.3325L563.681 1.83818C563.364 1.5217 563.364 0.994241 563.681 0.677765C563.997 0.361288 564.525 0.361288 564.841 0.677765L574.335 10.1721C574.652 10.4885 574.652 11.016 574.335 11.3325C574.177 11.4907 573.966 11.5786 573.755 11.5786Z"
          fill="#51D2E7"
        />
        <path d="M560.323 25.0466H483.102L478.812 20.739L560.323 20.2642V25.0466Z" fill="#51D2E7" />
        <path
          d="M560.322 46.2152C559.865 46.2152 559.496 45.846 559.496 45.3889V42.2944C559.496 41.8373 559.865 41.4681 560.322 41.4681C560.78 41.4681 561.149 41.8373 561.149 42.2944V45.3889C561.149 45.846 560.78 46.2152 560.322 46.2152Z"
          fill="#51D2E7"
        />
        <path d="M341.707 12.6509H251.669V20.2463H341.707V12.6509Z" fill="#51D2E7" />
        <path
          d="M560.323 164.173H371.878C370.964 164.173 370.243 163.435 370.243 162.538C370.243 161.642 370.982 160.903 371.878 160.903H558.67V145.659C558.67 144.745 559.408 144.024 560.305 144.024C561.202 144.024 561.94 144.763 561.94 145.659V162.556C561.94 163.47 561.202 164.191 560.305 164.191L560.323 164.173Z"
          fill="#51D2E7"
        />
        <path
          d="M560.323 170.96H371.878C371.421 170.96 371.052 170.591 371.052 170.134C371.052 169.677 371.421 169.307 371.878 169.307H560.323C560.78 169.307 561.149 169.677 561.149 170.134C561.149 170.591 560.78 170.96 560.323 170.96Z"
          fill="#51D2E7"
        />
        <path
          d="M573.755 182.371H564.261C563.804 182.371 563.434 182.001 563.434 181.544V172.05C563.434 171.593 563.804 171.224 564.261 171.224H573.755C574.212 171.224 574.581 171.593 574.581 172.05V181.544C574.581 182.001 574.212 182.371 573.755 182.371ZM565.07 180.736H572.929V172.876H565.07V180.736Z"
          fill="#51D2E7"
        />
        <path
          d="M573.755 182.371C573.544 182.371 573.333 182.283 573.175 182.125L563.68 172.63C563.364 172.314 563.364 171.786 563.68 171.47C563.997 171.153 564.524 171.153 564.841 171.47L574.335 180.964C574.652 181.281 574.652 181.808 574.335 182.125C574.177 182.283 573.966 182.371 573.755 182.371Z"
          fill="#51D2E7"
        />
        <path
          d="M564.244 182.371C564.033 182.371 563.822 182.283 563.664 182.125C563.348 181.808 563.348 181.281 563.664 180.964L573.158 171.47C573.475 171.153 574.002 171.153 574.319 171.47C574.635 171.786 574.635 172.314 574.319 172.63L564.824 182.125C564.666 182.283 564.455 182.371 564.244 182.371Z"
          fill="#51D2E7"
        />
        <path
          d="M560.323 157.756H483.102L478.812 162.063L560.323 162.538V157.756Z"
          fill="#51D2E7"
        />
        <path
          d="M560.322 141.334C559.865 141.334 559.496 140.965 559.496 140.508V116.684C559.496 116.227 559.865 115.858 560.322 115.858C560.779 115.858 561.148 116.227 561.148 116.684V140.508C561.148 140.965 560.779 141.334 560.322 141.334Z"
          fill="#51D2E7"
        />
        <path
          d="M221.376 164.173H32.9315C32.0173 164.173 31.2964 163.435 31.2964 162.538V145.642C31.2964 144.727 32.0349 144.007 32.9315 144.007C33.8282 144.007 34.5667 144.745 34.5667 145.642V160.885H221.376C222.29 160.885 223.011 161.624 223.011 162.52C223.011 163.417 222.273 164.156 221.376 164.156V164.173Z"
          fill="#51D2E7"
        />
        <path
          d="M221.376 170.96H32.9314C32.4743 170.96 32.1051 170.591 32.1051 170.133C32.1051 169.676 32.4743 169.307 32.9314 169.307H221.376C221.833 169.307 222.202 169.676 222.202 170.133C222.202 170.591 221.833 170.96 221.376 170.96Z"
          fill="#51D2E7"
        />
        <path
          d="M28.9935 182.37H19.4992C19.0421 182.37 18.6729 182.001 18.6729 181.544V172.05C18.6729 171.593 19.0421 171.223 19.4992 171.223H28.9935C29.4506 171.223 29.8199 171.593 29.8199 172.05V181.544C29.8199 182.001 29.4506 182.37 28.9935 182.37ZM20.308 180.735H28.1672V172.876H20.308V180.735Z"
          fill="#51D2E7"
        />
        <path
          d="M19.4991 182.371C19.2881 182.371 19.0771 182.283 18.9188 182.124C18.6024 181.808 18.6024 181.28 18.9188 180.964L28.4132 171.47C28.7296 171.153 29.2571 171.153 29.5736 171.47C29.89 171.786 29.89 172.314 29.5736 172.63L20.0793 182.124C19.921 182.283 19.71 182.371 19.4991 182.371Z"
          fill="#51D2E7"
        />
        <path
          d="M28.9934 182.371C28.7824 182.371 28.5714 182.283 28.4132 182.124L18.9188 172.63C18.6024 172.314 18.6024 171.786 18.9188 171.47C19.2353 171.153 19.7628 171.153 20.0793 171.47L29.5736 180.964C29.89 181.28 29.89 181.808 29.5736 182.124C29.4153 182.283 29.2043 182.371 28.9934 182.371Z"
          fill="#51D2E7"
        />
        <path
          d="M32.9329 157.756H110.136L114.443 162.063L32.9329 162.538V157.756Z"
          fill="#51D2E7"
        />
        <path
          d="M32.9314 141.334C32.4743 141.334 32.1051 140.965 32.1051 140.508V117.598C32.1051 117.141 32.4743 116.772 32.9314 116.772C33.3886 116.772 33.7578 117.141 33.7578 117.598V140.508C33.7578 140.965 33.3886 141.334 32.9314 141.334Z"
          fill="#51D2E7"
        />
        <path d="M251.529 170.134H341.566V162.538H251.529V170.134Z" fill="#51D2E7" />
        <foreignObject width="595" height="183">
          {children}
        </foreignObject>
      </svg>
    </>
  );
}

export function NewsletterSection() {
  const [email, setEmail] = useState('');

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await fetch('/api/klaviyo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setEmail('');
    }
  };

  return (
    <section className="relative py-16 sm:py-24">
      <Image
        src={bgImage}
        alt=""
        className={cx(
          'pointer-events-none absolute inset-0 -z-10 h-full w-full object-cover object-center'
        )}
      />
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative isolate px-6 py-24 sm:px-24 xl:py-32">
          <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {t.title}
          </h2>

          <Callout>
            <div className="w-full items-center justify-center md:absolute md:flex md:h-full">
              <form className="flex items-center" onSubmit={handleFormSubmit}>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className={cx(
                    'min-w-0 flex-auto bg-[#1F2C40] px-5 py-4 text-cyan placeholder:text-cyan',
                    'sm:text-sm sm:leading-6',
                    'focus:ring-inset focus:ring-white'
                  )}
                  placeholder={t.emailPlaceholder}
                />
                <button
                  type="submit"
                  className={cx(
                    'flex-none bg-yellow-thirtiary px-5 py-4 text-sm font-semibold text-black',
                    'hover:saturate-150'
                  )}
                >
                  {t.emailButton}
                </button>
              </form>
            </div>
          </Callout>
        </div>
      </div>
    </section>
  );
}
