import Image from 'next/image';

import image from '@/assets/images/home/token-presale.png';
import bgImage from '@/assets/images/home/intro-section-bg.svg';
import { cx } from '@/core/utils';
import { SnazzyLinkButton, type SnazzyLinkButtonProps } from '@/ui';

const t = {
  preTitle: 'Token Presale',
  title: 'Buy Token in the pre-sale phase',
  description:
    '$WARS is the native ERC20 token of the DEGEN WAR$ ecosystem. $WARS empowers the DEGEN WAR$ game and can be used to gain an in-game advantage.',
  button: {
    children: 'Join discord',
    href: 'https://discord.gg/degenwars',
    isExternal: true,
  } as SnazzyLinkButtonProps,
  image,
};

export function IntroSection() {
  return (
    <section className={cx('relative isolate py-[4.5rem]')}>
      <div
        className={cx(
          'container mx-auto grid max-w-screen-2xl grid-cols-1 gap-12 px-4',
          'md:grid-cols-2 md:gap-6',
          'lg:grid-cols-12 lg:items-center'
        )}
      >
        <div className={cx('flex justify-start', 'lg:col-start-1 lg:col-end-5')}>
          <Image
            src={t.image}
            alt={t.title}
            placeholder="blur"
            className={cx('h-auto w-full max-w-96 object-contain object-left', 'md:max-w-none')}
          />
        </div>

        <div className={cx('flex flex-col', 'lg:col-start-7 lg:col-end-12')}>
          <span
            className={cx(
              'mb-2 text-sm font-medium leading-none text-green text-glow-green',
              'md:mb-3 md:text-base'
            )}
          >
            {t.preTitle}
          </span>
          <h2 className={cx('mb-5 text-h4 text-brokenWhite', 'md:mb-8 md:text-h3')}>{t.title}</h2>
          <p className={cx('mb-8 font-display text-base text-neutral-400', 'md:mb-12 md:text-lg')}>
            {t.description}
          </p>
          <div>
            <SnazzyLinkButton {...t.button} />
          </div>
        </div>
      </div>

      <Image
        src={bgImage}
        alt=""
        className={cx(
          'pointer-events-none absolute inset-0 -z-10 h-full w-full object-cover object-center'
        )}
      />
    </section>
  );
}
