export function YoutubeVideo(props: React.ComponentPropsWithRef<'iframe'>) {
  const { src, ...rest } = props;

  return (
    <iframe
      src={`https://www.youtube-nocookie.com/embed/${src?.replace(
        'https://www.youtube.com/watch?v=',
        ''
      )}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
      {...rest}
    />
  );
}
