'use client';

import { Tab } from '@headlessui/react';
import Image, { type StaticImageData } from 'next/image';
import Link from 'next/link';

import { cx } from '@/core/utils';
import { YoutubeVideo } from '@/home/components';

export type TabsSectionProps = {
  title?: string;
  tabs: {
    label: string;
    content: PanelInnerProps;
  }[];
};

export function TabsSection(props: TabsSectionProps) {
  const { tabs, title } = props;

  return (
    <section className={cx('py-[4.5rem]')}>
      <div className={cx('container mx-auto max-w-screen-2xl px-4')}>
        {title && (
          <h2
            className={cx(
              'mb-7 text-center text-h4 !font-bold text-neutral-500',
              'xl:mb-10 xl:text-h3'
            )}
          >
            {title}
          </h2>
        )}

        {/* mobile */}
        <div className={cx('flex flex-col gap-4', 'md:hidden')}>
          {tabs.map(({ content: c }, index) => (
            <div
              key={index}
              className={cx(
                'border border-primary-700 backdrop-blur-[6px]',
                'bg-gradient-to-b from-[rgb(3,3,4,0.10)] to-[rgb(34,34,41,0.30)]'
              )}
            >
              <PanelInner {...c} />
            </div>
          ))}
        </div>

        {/* desktop */}
        <Tab.Group as="div" className={cx('hidden', 'md:block')}>
          <div className={cx('mb-2 overflow-x-auto', 'md:mb-7')}>
            <Tab.List className={cx('flex pb-3', 'md:justify-center')}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    cx(
                      'relative shrink-0 pb-px text-xs font-bold leading-[1.4] tracking-tighter',
                      'lg:text-sm',
                      'xl:text-base',
                      'focus:outline-none',
                      selected
                        ? 'bg-gradient-to-r from-yellow-thirtiary/15 via-yellow-thirtiary to-yellow-thirtiary/15'
                        : 'bg-gradient-to-r from-primary-500/15 via-primary-500 to-primary-500/15'
                    )
                  }
                >
                  {({ selected }) => (
                    <>
                      <div
                        className={cx(
                          'bg-gradient-to-b from-black via-black/90 to-black/80 px-7 py-5',
                          selected ? 'text-yellow-thirtiary' : 'text-neutral-500'
                        )}
                      >
                        {tab.label}
                      </div>
                      <div
                        className={cx(
                          'absolute inset-y-0 left-0 w-px bg-gradient-to-t to-transparent',
                          selected ? 'z-20 from-yellow-thirtiary/15' : 'z-10 from-primary-500/15'
                        )}
                      ></div>
                      <div
                        className={cx(
                          'absolute inset-y-0 right-0 w-px bg-gradient-to-t to-transparent',
                          selected ? 'z-20 from-yellow-thirtiary/15' : 'z-10 from-primary-500/15'
                        )}
                      ></div>
                    </>
                  )}
                </Tab>
              ))}
            </Tab.List>
          </div>
          <Tab.Panels>
            {tabs.map(({ content: c }, index) => (
              <Tab.Panel
                key={index}
                className={cx(
                  'grid grid-cols-12 items-center border border-primary-700 backdrop-blur-[6px]',
                  'bg-gradient-to-b from-[rgb(3,3,4,0.10)] to-[rgb(34,34,41,0.30)]'
                )}
              >
                <PanelInner {...c} />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </section>
  );
}

type PanelInnerProps = {
  title?: string;
  description?: string;
  button?: {
    label: string;
    href: string;
  };
  image?: StaticImageData;
  video?: string;
};

function PanelInner(props: PanelInnerProps) {
  const { description, title, button, image, video } = props;

  return (
    <>
      {video && (
        <YoutubeVideo
          width="748"
          height="521"
          src={video}
          className={cx(
            'col-span-full aspect-[748/521] h-auto w-full object-contain',
            'md:col-span-6',
            'lg:col-span-8'
          )}
        />
      )}

      {image && (
        <Image
          placeholder="blur"
          src={image}
          alt={title || ''}
          className={cx(
            'col-span-full h-auto w-full object-contain',
            'md:col-span-6',
            'lg:col-span-8'
          )}
          width={1024}
        />
      )}

      <div
        className={cx(
          'col-span-full flex flex-col gap-4 px-5 py-4',
          'md:col-span-6 md:px-10',
          'lg:col-span-4',
          'xl:px-20'
        )}
      >
        {title && (
          <h2 className={cx('text-xl !font-bold text-neutral-500', 'xl:text-h4')}>{title}</h2>
        )}

        {description && (
          <p className={cx('font-display text-sm text-neutral-500', 'xl:text-base')}>
            {description}
          </p>
        )}

        {button && (
          <div className={cx('mt-4')}>
            <Link
              href={button.href}
              className={cx(
                'inline-flex w-full items-center justify-center bg-yellow-thirtiary px-4 py-2 text-sm font-bold text-black',
                'sm:w-auto',
                'xl:py-3',
                '2xl:py-4 2xl:text-base',
                'hover:saturate-150'
              )}
            >
              {button.label}
            </Link>
          </div>
        )}
      </div>
    </>
  );
}
