import Image from 'next/image';

import { cx } from '@/core/utils';
import { SnazzyLinkButton, SnazzyLinkButtonProps } from '@/ui';
import bgImage from '@/assets/images/home/hero-section-bg.png';
import bgMobileImage from '@/assets/images/home/hero-section-mobile-bg.png';
import { routes } from '@/core/lib';

const t = {
  preTitle: 'A game by degens for degens',
  title: 'The ultimate battle for ETH',
  button: {
    children: 'Play now',
    href: routes.game.root,
    isExternal: false,
  } as SnazzyLinkButtonProps,
};

export function HeroSection() {
  return (
    // TODO: Make h-screen once header is fixed
    <section
      className={cx(
        'relative isolate h-[calc(100vh-77px)] overflow-hidden bg-gray-900',
        'md:h-auto'
      )}
    >
      <div
        className={cx(
          'mx-auto flex h-full max-w-7xl flex-col justify-end px-5 pb-24 pt-10 sm:pb-32',
          'md:px-8',
          'lg:flex-row lg:justify-start lg:px-8 lg:py-40'
        )}
      >
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-3xl lg:pt-8">
          <div className="sm:mt-32 lg:mt-16">
            <span
              className={cx(
                'mb-2 text-sm font-medium leading-none text-green text-glow-green',
                'md:mb-3 md:text-base'
              )}
            >
              {t.preTitle}
            </span>
          </div>

          <h1
            className={cx(
              'mt-5 text-4xl font-bold tracking-tight text-white',
              'md:mt-6 md:text-7xl'
            )}
          >
            {t.title}
          </h1>

          <div className={cx('mt-5 flex items-center gap-x-6 md:mt-10')}>
            <SnazzyLinkButton className={cx('w-full', 'md:w-60')} {...t.button} />
          </div>
        </div>
      </div>
      <div className="absolute inset-0 -z-10 h-full w-full">
        <div
          className={cx(
            'absolute z-10 h-full w-full bg-gradient-to-t from-black to-transparent to-30%'
          )}
        />
        <Image
          src={bgImage}
          alt=""
          className={cx(
            'pointer-events-none absolute hidden h-full w-full object-cover object-top',
            'lg:block'
          )}
        />
        <Image
          src={bgMobileImage}
          alt=""
          className={cx(
            'pointer-events-none absolute h-full w-full object-cover object-top',
            'lg:hidden'
          )}
        />
      </div>
    </section>
  );
}
